.earth{
    position:absolute;
    width:50px !important;
    height:50px !important;
    background-color: #29FE13;
    border-radius: 100% !important;
    border: "1px solid white";
}
.special-modal .modal-content{
    background-color: #000;
    color: #29FE13;
    font-family: Menlo;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
@keyframes expand{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.5);
    }
}
.image-card{
    animation: spin 10s infinite linear;
}
.earth:hover{
    animation: expand 1s forwards;
}
.orbit{
    border:3px dashed white;
    border-radius:50%;
    background:rgba(0,0,0,0);
    color:white;
    margin-top:20px;
    margin-right: auto;
    margin-left:auto;
    align-items: center;
    padding:0px !important;
    animation-name:spin;
    animation-duration: 50000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.moon{
    width:30px;
    height:30px;
    background-color: #29FE13;
    border-radius: 100%;
}
.moon:hover{
    animation: expand 0.5s forwards;
}
#planet-row{
    transform: translateY(-50%) !important;
}
.header-text{
    font-family: Menlo, monospace;
    font-size: 50px;
    font-weight:700;
    color: #29FE13;
    z-index: 1;
    visibility: visible !important;
}
.flipicon:hover{
    animation: spin 1s ease;
}
@keyframes spin{
    from{
        transform:rotate(0deg);
    }
    to{
        transform:rotate(180deg);
    }
}
@keyframes rotation{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.disc:hover{
    animation: rotation 0.5s;
    animation-duration: 1;
}

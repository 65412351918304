#navbar-vert{
    position:fixed;
    left:97%;
    transform:translateY(-50%);
    top: 50%;
    width: 28px;
    align-items: center;
}
*{
    font-family: 'Courier' !important;
}
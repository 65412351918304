.typewriter {
    font-family: Menlo, monospace;
    color: #29FE13;
	display: inline-block;
    z-index:1;
}
.typewriter-text {
    display: inline-block;
  	overflow: hidden;
  	letter-spacing: 2px;
 	animation: typing 1.5s steps(30, end), blink 1s step-end infinite;
    white-space: nowrap;
    font-size: 50px;
    font-weight: 700;
    padding:0px;
    border-right: 8px solid #29FE13;
    box-sizing: border-box;
    z-index:1;
}
.typewriter-text-2 {
    display: inline-block;
  	overflow: hidden;
  	letter-spacing: 2px;
 	animation: typing 1.5s steps(30, end), blink 1s step-end infinite;
    white-space: nowrap;
    font-size: 25px;
    font-weight: 700;
    padding:0px;
    border-right: 8px solid #29FE13;
    box-sizing: border-box;
    z-index:1;
}
.set-font {
    font-size: 20px;
    font-weight: 700;
    color: #29FE13;
    font-family: "Menlo";
}

@keyframes typing {
    from { 
        width: 0% 
    }
    to { 
        width: 100% 
    }
}

@keyframes blink {
    from, to { 
        border-color: transparent 
    }
    50% { 
        border-color: #29FE13; 
    }
}